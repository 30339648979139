
    import {defineComponent, WritableComputedRef, computed} from 'vue'
    import Post from '../../components/Post.vue'
    import Pagination from '../../components/Pagination.vue'

    export default defineComponent({

        name: 'PostsIndex',

        components: {
            Post,
            Pagination,
        },

        setup: () => {
            // @ts-ignore: Data on window
            const pageData: WritableComputedRef<any> = computed(() => window.pageData)

            return {
                pageData
            }
        }

    })
