
    import {ref, defineComponent, WritableComputedRef, computed} from 'vue'
    // @ts-ignore
    import Cookies from 'js-cookie'
    import {TextModuleType} from '../@types/modules'

    export default defineComponent({

        name: 'TextModule',

        props: {
            module: {
                type: Object as () => TextModuleType,
                required: true,
            }
        },

        setup: (props) => {
            const cookieKey = `module-collapsed-${props.module.moduleBodyHash}`
            let isCollapsed = ref(false)

            if (Cookies.get(cookieKey)) {
                isCollapsed.value = Cookies.get(cookieKey) === 'yes'
            } else {
                isCollapsed.value = props.module.moduleDefaultCollapseState
            }

            const onCollapseToggleBtnClick = () => {
                isCollapsed.value = ! isCollapsed.value
                Cookies.set(cookieKey, isCollapsed.value ? 'yes' : 'no', { expires: 365 })
            }

            return {
                isCollapsed,
                onCollapseToggleBtnClick,
            }
        }

    })
