
    // noinspection TypeScriptCheckImport
    // @ts-ignore: Unreachable code error
    import Prism from 'vue-prism-component'
    import {defineComponent} from 'vue'
    import {CodeModuleType} from '../@types/modules'

    export default defineComponent({

        name: 'TextModule',

        components: {
            Prism
        },

        props: {
            module: {
                type: Object as () => CodeModuleType,
                required: true
            }
        },

        setup: (props) => {
            return {}
        }

    })
