import {reactive, watch, onMounted, onBeforeUnmount} from 'vue'
import scssVariables from '@/js/scssVariables'
// @ts-ignore
import Cookies from 'js-cookie'

interface AppState {
    showNsfw: boolean
    isMobile: boolean
}

const appState = reactive<AppState>({
    showNsfw: false,
    isMobile: false,
})

const initAppState = () => {

    /**
     * State
     */

    appState.showNsfw = Cookies.get('show-nsfw') == 'yes'
    appState.isMobile = window.innerWidth < parseFloat(scssVariables.screenWidthSMMin)

    /**
     * Hooks
     */

    onMounted(() => {

        window.addEventListener('resize', onWindowResize)

        onWindowResize()

    })

    onBeforeUnmount(() => {

        window.removeEventListener('resize', onWindowResize)

    })

    /**
     * Events
     */

    const onWindowResize = () => {

        appState.isMobile = window.innerWidth < parseFloat(scssVariables.screenWidthSMMin)

    }

    /**
     * Watchers
     */

    watch(() => appState.showNsfw, (newValue) => {

        switch (newValue) {

            case true:
                Cookies.set('show-nsfw', 'yes', { expires: 365 })
                break

            default:
            case false:
                Cookies.remove('show-nsfw')
                break

        }

    })

}

export {
    appState,
    initAppState,
}
