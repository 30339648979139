
    import {ref, defineComponent, WritableComputedRef, computed} from 'vue'
    import {PostListingModuleType} from '../@types/modules'

    export default defineComponent({

        name: 'PostListingModule',

        props: {
            module: {
                type: Object as () => PostListingModuleType,
                required: true
            }
        },

        setup: (props) => {
            return {}
        }

    })
