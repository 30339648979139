
    import {ref, defineComponent, WritableComputedRef, computed} from 'vue'
    // @ts-ignore
    import {ReadMoreModuleType} from '@/vue/@types/modules'
    import {PostType} from '@/vue/@types/general'

    export default defineComponent({

        name: 'ReadMoreModule',

        props: {
            post: {
                type: Object as () => PostType,
                required: true,
            },
            module: {
                type: Object as () => ReadMoreModuleType,
                required: true,
            }
        },

    })
