import * as dayjs from 'dayjs'

export const postDate = (value: string | number | Date | dayjs.Dayjs | undefined) => {
    if (value) {
        return dayjs.utc(value).format('MMM Do, YYYY')
    } else {
        return 'Never'
    }
}

export const convertRemToPixels = (rem: number) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const scrollToY = (y: number, duration: number = 0, element: Element | null = document.scrollingElement) => {
    // cancel if already on target position
    if (!element || element.scrollTop === y) return

    const cosParameter = (element.scrollTop - y) / 2;
    let scrollCount = 0, oldTimestamp: number | null = null;

    function step(newTimestamp: number) {
        if (element && oldTimestamp !== null) {
            // if duration is 0 scrollCount will be Infinity
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
            if (scrollCount >= Math.PI) return element.scrollTop = y;
            element.scrollTop = cosParameter + y + cosParameter * Math.cos(scrollCount);
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);
}

export const scrollToTop = (duration: number = 0) => {
    scrollToY(0, duration, document.scrollingElement);
}

export const scrollToId = (id: string, duration: number = 0, additionalOffset: number = 0) => {
    const element = document.getElementById(id)
    if (element && document.scrollingElement) {
        const offset = Math.round(element.getBoundingClientRect().top);
        scrollToY(document.scrollingElement.scrollTop + offset + additionalOffset, duration);
    }
}

export const scrollToElement = (element: Element, duration: number = 0, additionalOffset: number = 0) => {
    if (document.scrollingElement) {
        const offset = Math.round(element.getBoundingClientRect().top);
        scrollToY(document.scrollingElement.scrollTop + offset + additionalOffset, duration);
    }
}
