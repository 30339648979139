
    import {defineComponent, WritableComputedRef, computed} from 'vue'

    export default defineComponent({

        name: 'Pagination',

        setup: (props) => {
            // @ts-ignore: Data on window
            const pageData: WritableComputedRef<any> = computed(() => window.pageData)

            return {
                pageData,
            }
        }

    })
