
    import {defineComponent, WritableComputedRef, computed} from 'vue'
    import TextModule from '../modules/Text.vue'
    import CodeModule from '../modules/Code.vue'
    import GalleryModule from '../modules/Gallery.vue'
    import ImageModule from '../modules/Image.vue'
    import PostListingModule from '../modules/PostListing.vue'
    import ReadMoreModule from '../modules/ReadMore.vue'
    import DisqusModule from '../modules/Disqus.vue'
    import {PostType} from '../@types/general'

    export default defineComponent({

        name: 'Post',

        components: {
            TextModule,
            CodeModule,
            GalleryModule,
            ImageModule,
            PostListingModule,
            ReadMoreModule,
            DisqusModule,
        },

        props: {
            post: {
                type: Object as () => PostType,
                required: false,
            },
            hideMeta: {
                type: Boolean,
                required: false,
                default: false,
            }
        },

        setup: (props) => {
            // @ts-ignore: Data on window
            const pageData: WritableComputedRef<any> = computed(() => window.pageData)
            // @ts-ignore: Data on window
            const post: WritableComputedRef<any> = computed(() => props.post || window.pageData.post)

            return {
                pageData,
                post,
            }
        }

    })
