
    import {ref, defineComponent, WritableComputedRef, computed} from 'vue'
    // @ts-ignore
    import {Disqus} from 'vue-disqus'
    import {DisqusModuleType} from '@/vue/@types/modules'
    import {PostType} from '@/vue/@types/general'

    export default defineComponent({

        name: 'DisqusModule',

        components: {
            Disqus
        },

        props: {
            post: {
                type: Object as () => PostType,
                required: true,
            },
            module: {
                type: Object as () => DisqusModuleType,
                required: true,
            }
        },

        setup: (props) => {
            const disqusPageConfig = {
                identifier: props.post.url,
            }

            return {
                disqusPageConfig,
            }
        }

    })
