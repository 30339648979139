import {
    postDate,
    scrollToY,
    scrollToElement,
    scrollToId,
    scrollToTop,
} from '@/js/helpers'

export default {
    methods: {

        postDate,
        scrollToY,
        scrollToElement,
        scrollToId,
        scrollToTop,

    }
}
