import App from '../vue/App.vue'
import {createApp} from 'vue'
import mitt from 'mitt'
import mixins from '../vue/mixins'

// DayJS
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc);
dayjs.extend(advancedFormat);

// Mitt
const emitter = mitt()

// Prism Code Highlighting
import 'prismjs'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/plugins/line-highlight/prism-line-highlight'
import 'prismjs/plugins/line-highlight/prism-line-highlight.css'
import 'prismjs/components/prism-scss'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-php'

// Font Awesome
// @ts-ignore
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faFolder,
    faFileAlt,
    faSpinner
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faHome, faFolder, faFileAlt, faSpinner)

// Fancybox
import '@fancyapps/ui/dist/fancybox.css'

// App main
const main = async () => {
    const app = createApp(App)

    app.config.globalProperties.emitter = emitter

    app.component('font-awesome-icon', FontAwesomeIcon)

    app.mixin(mixins)

    return app.mount('#app')
};

// Execute async function
main().then((root) => {})
