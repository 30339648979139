
    // noinspection TypeScriptCheckImport
    // @ts-ignore: Unreachable code error
    import Isotope from 'isotope-layout'
    // noinspection TypeScriptCheckImport
    // @ts-ignore: Unreachable code error
    import ImagesLoaded from 'imagesloaded'
    // noinspection TypeScriptCheckImport
    // @ts-ignore: Unreachable code error
    import {Fancybox} from '@fancyapps/ui'
    import {ref, defineComponent, ComputedRef, computed, onMounted, nextTick, watch} from 'vue'
    import {appState} from '../composables/useAppState'
    import {GalleryModuleType} from '../@types/modules'
    import {scrollToId, convertRemToPixels} from '@/js/helpers'
    import {ImageType} from '@/vue/@types/general'
    import scssVariables from '@/js/scssVariables'

    export default defineComponent({

        name: 'GalleryModule',

        props: {
            module: {
                type: Object as () => GalleryModuleType,
                required: true
            }
        },

        setup: (props) => {

            /**
             * Local State
             */

            const loading = ref(true)
            const isViewMoreActive = ref(false)
            const visibleModuleImages = ref(appState.isMobile ? props.module.moduleImages.slice(0, props.module.moduleViewMoreMobileCutoff) : props.module.moduleImages)

            const fancyBoxImages: ComputedRef<any> = computed(() => {
                return props.module.moduleImages.map(image => {
                    return {
                        src: image.imageNsfw && !appState.showNsfw ? image.nsfwUrl : image.url,
                        thumb: image.imageNsfw && !appState.showNsfw ? image.modalThumbnailNsfwUrl : image.modalThumbnailUrl,
                        caption: image.imageDescription,
                        type: 'image'
                    }
                })
            })

            let fancybox: Fancybox
            let iso: Isotope
            let $grid: HTMLElement | null

            /**
             * Functions
             */

            const initIsotope = (callback: Function | null = null) => {

                $grid = document.getElementById(`gallery-grid-${props.module.id}`)

                loading.value = true

                iso = new Isotope($grid, {
                    itemSelector: '.grid-item',
                    percentPosition: true,
                    masonry: {
                        columnWidth: `#gallery-grid-sizer-${props.module.id}`
                    }
                })

                ImagesLoaded($grid, () => {

                    loading.value = false

                    nextTick(() => {

                        iso.layout()

                        if (typeof callback === 'function') {
                            callback()
                        }

                    })

                })

            }

            const setVisibleModuleImages = (images: ImageType[]) => {

                loading.value = true

                const previouslyVisibleModuleImagesCount = visibleModuleImages.value.length

                visibleModuleImages.value = images

                iso.destroy()

                nextTick(() => {

                    initIsotope(() => {
                        scrollToId(`gallery-module-${props.module.id}-image-${previouslyVisibleModuleImagesCount}`, 300, -(convertRemToPixels(parseFloat(scssVariables.gutter)) / 2) + 1)
                    })

                })

            }

            /**
             * Events
             */

            const onImageClick = (index: number) => {

                 fancybox = new Fancybox(fancyBoxImages.value, {
                     startIndex: index
                 })

            }

            const onViewMoreClick = () => {

                isViewMoreActive.value = true
                setVisibleModuleImages(props.module.moduleImages)

            }

            /**
             * Hooks
             */

            onMounted(() => {

                initIsotope()

            })

            /**
             * Watchers
             */

            watch(() => appState.isMobile, (newValue) => {

                if (isViewMoreActive.value) {
                    return
                }

                switch (newValue) {

                    case true:
                        setVisibleModuleImages(props.module.moduleImages.slice(0, props.module.moduleViewMoreMobileCutoff))
                        break

                    default:
                    case false:
                        setVisibleModuleImages(props.module.moduleImages)
                        break

                }

            })

            return {
                appState,
                loading,
                visibleModuleImages,
                isViewMoreActive,
                onImageClick,
                onViewMoreClick,
            }
        }

    })
