
    import {computed, defineComponent, reactive, watch, onMounted, WritableComputedRef} from 'vue'
    import {appState, initAppState} from './composables/useAppState'
    import HomeEntry from './sections/home/Entry.vue'
    import PostsIndex from './sections/posts/Index.vue'
    import PostsEntry from './sections/posts/Entry.vue'
    import CategoriesIndex from './sections/categories/Index.vue'
    import CategoriesPostsIndex from './sections/categories/posts/Index.vue'

    export default defineComponent({

        name: 'App',

        components: {
            HomeEntry,
            PostsIndex,
            PostsEntry,
            CategoriesIndex,
            CategoriesPostsIndex,
        },

        setup: (props) => {
            // @ts-ignore: Data on window
            const pageData: WritableComputedRef<any> = computed(() => window.pageData)

            initAppState()

            return {
                appState,
                pageData,
            }
        }

    })
