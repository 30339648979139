
    // noinspection TypeScriptCheckImport
    // @ts-ignore: Unreachable code error
    import {Fancybox} from '@fancyapps/ui'
    import {ref, defineComponent, WritableComputedRef, computed, ComputedRef} from 'vue'
    import {appState} from '../composables/useAppState'
    import {ImageModuleType} from '../@types/modules'

    export default defineComponent({

        name: 'ImageModule',

        props: {
            module: {
                type: Object as () => ImageModuleType,
                required: true
            }
        },

        setup: (props) => {
            const fancyBoxImages: ComputedRef<any> = computed(() => {
                return [props.module.image].map(image => {
                    return {
                        src: image.imageNsfw && !appState.showNsfw ? image.nsfwUrl : image.url,
                        thumb: image.imageNsfw && !appState.showNsfw ? image.modalThumbnailNsfwUrl : image.modalThumbnailUrl,
                        caption: image.imageDescription,
                        type: 'image'
                    }
                })
            })

            let fancybox;

            const onImageClick = (index: number) => {

                fancybox = new Fancybox(fancyBoxImages.value, {
                    startIndex: index
                })

            }

            return {
                appState,
                onImageClick
            }
        }

    })
